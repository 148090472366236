import "../Styles/index.scss";

import $ from "jquery";
import ScrollMagic from "scrollmagic";
import { TweenMax, TimelineMax, TimelineLite, Sine, Quad, Linear, gsap } from "gsap";

import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

const mediaqueries = {
	"small": "screen and (min-width: 32em)",
	"twocolumn": "screen and (min-width: 80em) and (min-height: 42em)",
};

let controller: any;
let panoramaTimeline: TimelineMax | TimelineLite | null;

let headerOverlay = $(".block.header .overlay");
let logo = $(".logo");
let blocks = $(".block");

function setupAnimations() {
	// init controller
	controller = new ScrollMagic.Controller();
	controller.scrollTo(function (scrollTop: number) {
		TweenMax.to(window, 0.5, {
			scrollTo: { y: scrollTop }
		});
	});

	// header panorama
	if ($(".block-panorama").length > 0) {
		var panorama = $(".block-panorama .panorama");
		let aspect = parseInt(panorama.attr("width") || "1", 10) / parseInt(panorama.attr("height") || "1", 10);

		panoramaTimeline = new TimelineMax({
			yoyo: true,
			repeat: -1
		}).to(panorama[0], 16, {
			x: -1 * (window.innerHeight * aspect - window.innerWidth),
			ease: Sine.easeInOut
		});
	}

	// logo animation
	if ($("body").hasClass("animate-logo")) {
		new ScrollMagic.Scene({ triggerElement: headerOverlay[0], triggerHook: "onEnter", duration: "50%", offset: window.innerHeight / 2 })
			.setTween(logo[0], { width: "33%", top: window.innerHeight / 3 - (logo.height() || 0), ease: Quad.easeIn })
			.addTo(controller);
		new ScrollMagic.Scene({ triggerElement: headerOverlay[0], triggerHook: "onLeave", duration: "50%", offset: window.innerHeight / 2 })
			.setTween(logo[0], { width: "10%", top: "3%", ease: Quad.easeOut })
			.addTo(controller);
	} else {
		new ScrollMagic.Scene({ triggerElement: $(".block")[1], triggerHook: "onEnter", duration: "25%" })
			.setTween(logo[0], { width: "10%", ease: Quad.easeOut })
			.addTo(controller);
	}

	blocks.not(".block--flow").each(function (i, el) {
		let timeline = new TimelineMax();
		var imageScene = new ScrollMagic.Scene({ triggerElement: el, triggerHook: "onLeave", duration: "100%" })
			.setPin($(el).find(".image")[0])
			.addTo(controller);

		let overlay = $(el).find(".overlay");
		if ($(el).hasClass("has-icon")) {
			timeline.add(TweenMax.to(overlay[0], 1, { backgroundColor: "white", ease: Linear.easeNone }));
		}

		var overlayScene = new ScrollMagic.Scene({ triggerElement: overlay[0], triggerHook: "onEnter", duration: "50%" })
			.setTween(timeline)
			.addTo(controller);

		new ScrollMagic.Scene({ triggerElement: el, triggerHook: "onLeave", duration: "100%" })
			.setTween(overlay[0], 1, { y: "-100%", ease: Linear.easeNone })
			.addTo(controller);

		$(el).data("imageScene", imageScene);
		$(el).data("overlayScene", overlayScene);
	});

	$(".jump-overlay").click(function () {
		var block = $(this).parentsUntil(".block").parent();
		controller.scrollTo(block.data("overlayScene"));
	});
	$(".jump-block").click(function () {
		var nextBlock = $(this).parentsUntil(".block").parent().next();
		if (nextBlock.is("footer")) {
			controller.scrollTo(nextBlock[0]);
		} else {
			controller.scrollTo(nextBlock.data("imageScene"));
		}
	});
}

function destroyAnimations() {
	if (controller != null) {
		controller.destroy(true);
		controller = null;
	}
	if (panoramaTimeline != null) {
		panoramaTimeline.seek(0, false);
		panoramaTimeline.kill();
		panoramaTimeline = null;
		$(".panorama").css("transform", "");
	}
}

var twoColumn = window.matchMedia(mediaqueries.twocolumn);
if (twoColumn.matches) {
	setupAnimations();
}
twoColumn.addEventListener("change", e => {
	if (e.matches) {
		setupAnimations();
	} else {
		destroyAnimations();
	}
});

import { initializeMaps } from "@wagich/bluc-map";
initializeMaps();
